<template>
  <div class="pa-4">
    <p class="font-weight-bold text-center body-2 text-uppercase">
      {{ title }}
    </p>
    <Bar
      :chart-options="chartOptions"
      :chart-data="dataChart"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChartHorizontalDashboard",
  components: {
    Bar,
  },
  props: {
    title: { type: String },
    chartId: { type: String, default: "bar-chart" },
    datasetIdKey: { type: String, default: "label" },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 300 },
    cssClasses: { default: "", type: String },
    // styles: { type: Object, default: () => {} },
    plugins: { type: Array, default: () => [] },
    dataChart: { type: Object, default: () => {} },
    legend: {
      type: Boolean,
      default: false,
    },
    scaleX: {
      type: Number,
      default: 200,
    },
  },
    created() {
    this.chartOptions.scales.x.suggestedMax = this.scaleX
  },
  data() {
    return {
      styles: {
        maxHeight: "300px",
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        scales: {
          x: {
            stacked: true,
                        suggestedMin: 0,
            suggestedMax: 0,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  },
};
</script>
