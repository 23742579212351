<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <div class="d-flex justify-space-between align-center mb-4">
        <v-btn small depressed color="fmq_gray" dark outlined to="/">
          <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
        >
      </div>

      <v-card elevation="1" class="pt-8 mt-4 mb-4 pa-4">
        <FilterAtendimentos
          @buscar="buscar($event)"
          @baixar="baixar($event)"
          :loading="loading"
          :loadingBtn="loadingBtn"
          :funcionarioId="false"
          :produtoId="false"
          :consolidado="false"
        />
      </v-card>

      <v-card elevation="1" class="pt-8 mt-4 mb-4 pa-4">
        <v-progress-circular
          v-if="loading"
          :size="100"
          :width="4"
          color="fmq_black"
          indeterminate
          class="d-flex mx-auto py-8 mt-8"
        ></v-progress-circular>
        <div v-else>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab
              v-for="(item, index) in linhas"
              :key="item.linhaId"
              @click="setTab(index)"
            >
              {{ item.linhaNome }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in linhas" :key="item.linhaId">
              <v-row dense>
                <v-col
                  :cols="item.periodoComparativo ? '6' : ''"
                  v-if="item.periodo"
                >
                  <div class="pa-4">
                    <p
                      class="font-weight-bold text-center body-1 text-uppercase mb-4"
                    >
                      {{ setDate(item.periodo.periodoInicio) }} a
                      {{ setDate(item.periodo.periodoTermino) }}
                    </p>
                    <LineChartDashboard
                      title="Solicitações/Produto mensal"
                      :dataChart="chartLine"
                      :legend="true"
                      class="mb-8"
                      :scaleY="item.escalaProdutoTotalMensal"
                    />
                    <PieChartDashboard
                      class="mb-8"
                      title="Participação"
                      :dataChart="chartPie"
                    />
                    <BarChartHorizontalDashboard
                      title="Especialidade/Produto"
                      :dataChart="chartBar"
                      :legend="true"
                      class="mb-8"
                      :scaleX="item.escalaTotalProdutos"
                    />
                    <TabelaAtendimentos
                      title="Solicitações/Motivo"
                      keyTable="solicitacaoAssunto"
                      :data="item.periodo.totalPorAssunto"
                      class="mb-8"
                      textLeft="Quantidade"
                    /></div
                ></v-col>
                <v-col cols="6" v-if="item.periodoComparativo">
                  <div class="pa-4">
                    <p
                      class="font-weight-bold text-center body-1 text-uppercase mb-4"
                    >
                      {{ setDate(item.periodoComparativo.periodoInicio) }} a
                      {{ setDate(item.periodoComparativo.periodoTermino) }}
                    </p>
                    <LineChartDashboard
                      title="Solicitações/Produto mensal - Comparativo"
                      :dataChart="chartLineComparativo"
                      :legend="true"
                      class="mb-8"
                      :scaleY="item.escalaProdutoTotalMensal"
                    />
                    <PieChartDashboard
                      class="mb-8"
                      title="Participação - Comparativo"
                      :dataChart="chartPieComparativo"
                    />
                    <BarChartHorizontalDashboard
                      title="Especialidade/Produto - Comparativo"
                      :dataChart="chartBarComparativo"
                      :legend="true"
                      class="mb-8"
                      :scaleX="item.escalaTotalProdutos"
                    />
                    <TabelaAtendimentos
                      title="Solicitações/Motivo - Comparativo"
                      keyTable="solicitacaoAssunto"
                      :data="item.periodoComparativo.totalPorAssunto"
                      class="mb-8"
                      textLeft="Quantidade"
                    /></div
                ></v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <ErrorMessage v-if="showError" message="Dados não encontrados" />
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { stringToHex } from "@/utils";
import LineChartDashboard from "@/components/relatorios/LineChartDashboard.vue";
import FilterAtendimentos from "@/components/relatorios/FilterAtendimentos.vue";
import PieChartDashboard from "@/components/relatorios/PieChartDashboard.vue";
import BarChartHorizontalDashboard from "@/components/relatorios/BarChartHorizontalDashboard.vue";
import TabelaAtendimentos from "@/components/relatorios/TabelaAtendimentos.vue";
import {
  solicitacoesPorLinhaJson,
  solicitacoesPorPLinhaExel,
} from "@/services/relatorio";
export default {
  name: "RelatorioAtendimentosMedicoAprovador",
  components: {
    LineChartDashboard,
    FilterAtendimentos,
    PieChartDashboard,
    BarChartHorizontalDashboard,
    TabelaAtendimentos,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Relatórios",
        disabled: false,
        to: "",
      },
      {
        text: "Solicitações por Linha",
        disabled: true,
        to: "",
      },
    ],
    formData: {},
    loading: false,
    linhas: [],
    tab: 0,
    loadingBtn: false,
    chartLine: null,
    chartLineComparativo: null,
    chartPie: null,
    chartPieComparativo: null,
    chartBar: null,
    chartBarComparativo: null,
    showError: false,
  }),
  methods: {
    setTab(index) {
      this.setChartLine(this.linhas[index]);
      this.setChartPie(this.linhas[index]);
      this.setChartBar(this.linhas[index]);

      if (this.linhas[index].periodoComparativo) {
        this.setChartLineComparativo(this.linhas[index]);
        this.setChartPieComparativo(this.linhas[index]);
        this.setChartBarComparativo(this.linhas[index]);
      }
    },
    async getData() {
      this.showError = false;
      this.loading = true;
      this.linhas = null;
      try {
        const resp = await solicitacoesPorLinhaJson(this.formData);
        this.linhas = this.convertToArray(resp.data);
        this.setChartLine(this.linhas[this.tab]);
        this.setChartPie(this.linhas[this.tab]);
        this.setChartBar(this.linhas[this.tab]);
        if (this.linhas[this.tab].periodoComparativo) {
          this.setChartLineComparativo(this.linhas[this.tab]);
          this.setChartPieComparativo(this.linhas[this.tab]);
          this.setChartBarComparativo(this.linhas[this.tab]);
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.showError = true;
        console.error(e);
      }
    },
    async downloadData() {
      this.loadingBtn = true;
      try {
        const resp = await solicitacoesPorPLinhaExel(this.formData);
        window.open(resp.data.url, "_blank");
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        console.error(e);
      }
    },
    setChartLine(linha) {
      const chartData = {
        labels: this.getMonthsBetween(
          linha.periodo.periodoInicio,
          linha.periodo.periodoTermino
        ),
        datasets: this.getDataLine(linha.periodo.produtos),
      };
      this.chartLine = chartData;
    },
    setChartLineComparativo(linha) {
      const chartData = {
        labels: this.getMonthsBetween(
          linha.periodoComparativo.periodoInicio,
          linha.periodoComparativo.periodoTermino
        ),
        datasets: this.getDataLine(linha.periodoComparativo.produtos),
      };
      this.chartLineComparativo = chartData;
    },
    getDataLine(produtos) {
      const produtosArr = this.convertToArray(produtos);
      const datasets = produtosArr.map((produto) => ({
        label: produto.produtoNome,
        backgroundColor: stringToHex(produto.produtoNome),
        data: Object.values(produto.totalMensal),
        borderColor: stringToHex(produto.produtoNome),
      }));
      return datasets;
    },
    setChartPie(linha) {
      const produtosArr = this.convertToArray(linha.periodo.produtos);
      const pieChart = {
        labels: produtosArr.map((item) => item.produtoNome),
        datasets: [
          {
            backgroundColor: produtosArr.map((item) =>
              stringToHex(item.produtoNome)
            ),
            data: produtosArr.map((item) => item.total),
          },
        ],
      };
      this.chartPie = pieChart;
    },
    setChartPieComparativo(linha) {
      const produtosArr = this.convertToArray(
        linha.periodoComparativo.produtos
      );
      const pieChart = {
        labels: produtosArr.map((item) => item.produtoNome),
        datasets: [
          {
            backgroundColor: produtosArr.map((item) =>
              stringToHex(item.produtoNome)
            ),
            data: produtosArr.map((item) => item.total),
          },
        ],
      };
      this.chartPieComparativo = pieChart;
    },
    setChartBar(linha) {
      const produtosArr = this.convertToArray(linha.periodo.produtos);
      const chartData = {
        labels: produtosArr.map((produto) => produto.produtoNome),
        datasets: this.getDataBar(linha.periodo.produtos),
      };
      this.chartBar = chartData;
    },
    setChartBarComparativo(linha) {
      const produtosArr = this.convertToArray(
        linha.periodoComparativo.produtos
      );
      const chartData = {
        labels: produtosArr.map((produto) => produto.produtoNome),
        datasets: this.getDataBar(linha.periodoComparativo.produtos),
      };
      this.chartBarComparativo = chartData;
    },
    getDataBar(produtos) {
      const produtosArr = this.convertToArray(produtos);
      const especialidades = produtosArr
        .map((produto, produtoIndex) => {
          return produto.totalPorEspecilidade.map((especialidade) => ({
            produtoNome: produto.produtoNome,
            especialidadeNome:
              especialidade.medicosSolicitantesEspecialidadeNome,
            total: especialidade.total,
            produtoIndex: produtoIndex,
          }));
        })
        .flat();

      const datasets = [];
      especialidades.map((especialidade) => {
        const exists = datasets.some(
          (data) => data.label === especialidade.especialidadeNome
        );
        if (!exists) {
          datasets.push({
            label: especialidade.especialidadeNome,
            backgroundColor: stringToHex(especialidade.especialidadeNome),
            data: Array.from({ length: produtosArr.length }, () => 0),
          });
        }
      });
      especialidades.map((especialidade) => {
        {
          const index = datasets.findIndex(
            (data) => data.label === especialidade.especialidadeNome
          );
          datasets[index].data[especialidade.produtoIndex] =
            especialidade.total;
        }
      });
      return datasets;
    },
    setDate(date) {
      const newDate = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      return newDate;
    },
    buscar(event) {
      this.formData = event;
      this.getData();
    },
    baixar(event) {
      this.formData = event;
      this.downloadData();
    },
    getMonthsBetween(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const months = [];

      let current = new Date(start.getFullYear(), start.getMonth() + 1, 1);

      while (current <= end) {
        const year = current.getFullYear();
        const month = String(current.getMonth() + 1).padStart(2, "0");
        months.push(`${year}-${month}`);
        current.setMonth(current.getMonth() + 1);
      }

      return months;
    },
    convertToArray(obj) {
      const resultArray = [];
      for (const key in obj) {
        resultArray.push(obj[key]);
      }
      return resultArray;
    },
  },
};
</script>

<style></style>
